import { filter } from 'lodash';
import { useState, useEffect } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Container,
  Typography,
  TableContainer,
} from '@mui/material';

import Button from '@mui/material/Button';
import NumberFormat from 'react-number-format';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSearchParams, useNavigate, NavLink } from "react-router-dom";
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { UserListHead } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/saleItems';
import Iconify from '../components/Iconify';
import { useApi } from '../api'
import OptionsButton from '../components/OptionsButton';



// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'account', label: 'Account', alignRight: false },
  { id: 'id', label: 'Sale ID', alignRight: false },
  { id: 'date', label: 'Date', alignRight: false },
  { id: 'quantity', label: 'Product Qty', alignRight: false },
  { id: 'costFifo', label: 'Cost Fifo', alignRight: false },
  { id: 'costAverage', label: 'Cost Avg', alignRight: false },
  { id: 'subTotal', label: 'SubTotal', alignRight: false },
  { id: 'tax', label: 'Tax', alignRight: false },
  { id: 'total', label: 'Total', alignRight: false },
];

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function User() {
  const { get } = useApi();
  const [sales, setSales] = useState([]);
  const [open, setOpen] = useState()
  const [page, setPage] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [order, setOrder] = useState('asc');
  const navigate = useNavigate();

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const startDate = searchParams.get('startDate') || ''
  const endDate = searchParams.get('endDate') || ''
  const categoryName = searchParams.get('categoryName') || ''
  const manufacturerName = searchParams.get('manufacturerName') || ''
  const vendorName = searchParams.get('vendorName') || ''
  const itemName = searchParams.get('itemName') || ''
  const clientId = searchParams.get('clientId') || ''
  const upc = searchParams.get('upc') || ''

  useEffect(() => {

    const fetchData = async () => {
      try {
        const results = await get(`sales?clientId=${clientId}&startDate=${startDate}&endDate=${endDate}&categoryName=${encodeURIComponent(categoryName)}&manufacturerName=${encodeURIComponent(manufacturerName)}&vendorName=${encodeURIComponent(vendorName)}&itemName=${encodeURIComponent(itemName)}&upc=${encodeURIComponent(upc)}`)
        setSales(results.sales);
      }
      catch (e) {
        console.log('error', e)
      }
    }

    fetchData()
  }, [get, searchParams, clientId, startDate, endDate, categoryName, manufacturerName, vendorName, itemName, upc])



  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };


  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);


  const totalCalcFIFOCost = sales.map(e => e.calcFIFOCost ? parseFloat(e.calcFIFOCost) : 0).reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  const totalCalcAvgCost = sales.map(e => e.calcAvgCost ? parseFloat(e.calcAvgCost) : 0).reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  const totalCalcSubtotal = sales.map(e => e.calcSubtotal ? parseFloat(e.calcSubtotal) : 0).reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  const totalCalcTaxTotal = sales.map(e => e.taxTotal ? parseFloat(e.taxTotal) : 0).reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  const totalCalcTotal = sales.map(e => e.total ? parseFloat(e.total) : 0).reduce((previousValue, currentValue) => previousValue + currentValue, 0)



  return (
    <Page title="User">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Sales ({sales.length})
          </Typography>
        </Stack>

        <Dialog
          open={Boolean(open)}
          onClose={() => { setOpen(false) }}
          scroll={'body'}
          maxWidth='lg'
          fullWidth
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">Subscribe</DialogTitle>
          <DialogContent>
            <DialogContentText
              id="scroll-dialog-description"
              tabIndex={-1}
            >
              {open ? Object.entries(open).map((e, index) => <div key={index} style={{ display: 'flex', padding: '5px', borderBottom: '1px solid grey' }}>
                <div style={{ flex: '1' }}>{e[0]}</div>
                <div style={{ flex: '1', textAlign: 'right' }}>{e[1]}</div>
              </div>) : null}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { setOpen(false) }}>Cancel</Button>
          </DialogActions>
        </Dialog>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {sales.map((row, index) => {
                    const { clientId, clientName, saleID, timeStamp, calcFIFOCost, calcAvgCost, calcSubtotal, taxTotal, total } = row;

                    return (
                      <TableRow
                        hover
                        key={saleID}
                        tabIndex={-1}
                      >
                        <TableCell align="left">{clientName}                        </TableCell>
                        <TableCell align="left"><NavLink to={`/dashboard/sale-details?accountId=${clientId}&saleId=${saleID}`}>{saleID}</NavLink>
                        </TableCell>
                        <TableCell align="left">{timeStamp}</TableCell>
                        <TableCell align="right">?</TableCell>
                        <TableCell align="right">{calcFIFOCost}</TableCell>
                        <TableCell align="right">{calcAvgCost}</TableCell>
                        <TableCell align="right">{calcSubtotal}</TableCell>
                        <TableCell align="right">{taxTotal}</TableCell>
                        <TableCell align="right">{total}</TableCell>
                        <TableCell align="right">
                          <OptionsButton
                            options={[
                              {
                                name: 'View Sales Details',
                                onClick: () => {
                                  navigate(`/dashboard/sale-details?accountId=${clientId}&saleId=${saleID}`)
                                }
                              },
                              {
                                name: 'Source Data',
                                onClick: () => {
                                  setOpen(row)
                                }
                              }
                            ]}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell align="right"><NumberFormat value={totalCalcFIFOCost} displayType={'text'} thousandSeparator decimalScale={2} fixedDecimalScale /></TableCell>
                    <TableCell align="right"><NumberFormat value={totalCalcAvgCost} displayType={'text'} thousandSeparator decimalScale={2} fixedDecimalScale /></TableCell>
                    <TableCell align="right"><NumberFormat value={totalCalcSubtotal} displayType={'text'} thousandSeparator decimalScale={2} fixedDecimalScale /></TableCell>
                    <TableCell align="right"><NumberFormat value={totalCalcTaxTotal} displayType={'text'} thousandSeparator decimalScale={2} fixedDecimalScale /></TableCell>
                    <TableCell align="right"><NumberFormat value={totalCalcTotal} displayType={'text'} thousandSeparator decimalScale={2} fixedDecimalScale /></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}
