import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import { useApi } from '../api'

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'lsID', label: 'LightSpeed ID', alignRight: false },
  { id: 'posID', label: 'POS ID', alignRight: false },
  { id: 'importErrorDate', label: 'Latest Errors', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export default function User() {
  const { get } = useApi();

  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [accounts, setAccounts] = useState([]);
  const [importLogs, setImportLogs] = useState([]);
  const [isImporting, setIsImporting] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const results = await get(`accounts`)
        setAccounts(results.accounts);

        const importResults = await get(`importLogs?type=error&sort=datetime`)
        setImportLogs(importResults.logs);
      }
      catch (e) {
        console.log('error', e)
      }
    }

    fetchData()
  }, [])



  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };


  const handleImport = async () => {
    setIsImporting(true)

    await get(`import`)

    const importResults = await get(`importLogs?type=error&sort=datetime`)
    setImportLogs(importResults.logs);

    setIsImporting(false)
  }

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            LightSpeed Accounts
          </Typography>

          <Button variant="contained" onClick={handleImport} startIcon={<Iconify icon="eva:plus-fill" />} disabled={isImporting}>
            Import
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {accounts.map((row) => {
                    const { id, name, status, posID, lsAccountID } = row;
                    const isItemSelected = selected.indexOf(name) !== -1;

                    const logs = importLogs.filter(e => e.accountID === lsAccountID)
                    let errorMsg = '0 Errors'
                    if (logs.length > 0) {
                      errorMsg = `${logs.length} Errors`
                    }

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell align="left">{name}</TableCell>
                        <TableCell align="left">
                          <Label variant="ghost" color={status === 'active' ? 'success' : 'error'}>
                            {sentenceCase(status)}
                          </Label>
                        </TableCell>
                        <TableCell align="left">{lsAccountID}</TableCell>
                        <TableCell align="left">{posID}</TableCell>
                        <TableCell align="left">
                          <Label variant="ghost" color={logs.length === 0 ? 'success' : 'error'}>{errorMsg}</Label>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {accounts.length === 0 && (
                    <TableRow style={{ height: 53 * 10 }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}
