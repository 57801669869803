import { faker } from '@faker-js/faker';

// ----------------------------------------------------------------------

const users = [...Array(24)].map(() => ({
  uuid: faker.datatype.uuid(),
  name: faker.name.findName(),
  manufacturer: faker.company.companyName(),
  vendor: faker.company.companyName(),
  category: faker.random.word(),
  costFifo: faker.commerce.price(),
  costAverage: faker.commerce.price(),
  quantity: faker.random.number(1,25),
  price: faker.random.number(1,25),
}));

export default users;
