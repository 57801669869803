import { useMemo } from "react";

// export const host = "http://localhost:4000";
export const host = "https://erp-api.simplyforlife.com";


export const useApi = () => {
	const token = sessionStorage.getItem('session_token') || '';
	console.log('token', token, sessionStorage.getItem('session_token'))

	return useMemo(() => ({
		get: get.bind(this, token),
		del: del.bind(this, token),
		post: post.bind(this, token),
		download: download.bind(this, token),
		upload: upload.bind(this, token)
	}), [token]);
}

const api = async (method, token, path, data) => {
	try {
		let response;
		if (method === 'post') {
			response = await fetch(`${host}/${path}`,
				{
					method: 'POST',
					headers: {
						'Authorization': `Bearer ${token}`,
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(data)
				});

		} else if (method === 'download') {
			response = await fetch(`${host}/${path}`,
				{
					method: 'GET',
					headers: {
						'Authorization': `Bearer ${token}`,
						'Accept': 'application/json',
					},
					body: data
				});

			const blobData = await response.blob()
			return blobData

		} else if (method === 'upload') {
			response = await fetch(`${host}/${path}`,
				{
					method: 'POST',
					headers: {
						'Authorization': `Bearer ${token}`,
						'Accept': 'application/json',
					},
					body: data
				});

		} else if (method === 'delete') {
			response = await fetch(`${host}/${path}`,
				{
					method: 'DELETE',
					headers: {
						'Authorization': `Bearer ${token}`,
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					}
				});

		} else {
			response = await fetch(`${host}/${path}`,
				{
					method: 'GET',
					headers: {
						'Authorization': `Bearer ${token}`,
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					}
				});
		}

		const json = await response.json();

		if (json.status === 'success') {
			return json.data;
		}

		throw Error('error');
	}
	catch (error) {
		throw Error(error)
	}
}

const get = (token, path) => {
	return api('get', token, path, {});
}

const post = (token, path, data) => {
	return api('post', token, path, data);
}

const download = (token, path, data) => {
	return api('download', token, path, data);
}

const upload = (token, path, data) => {
	return api('upload', token, path, data);
}

const del = (token, path, data) => {
	return api('delete', token, path, data);
}
