import { useState } from 'react';
import { format, parseISO, startOfQuarter, endOfQuarter, startOfMonth, subMonths, endOfMonth, startOfYear, endOfYear } from 'date-fns'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { TextField, MenuItem } from '@mui/material';
// component

export default function DateSelector({ startDate, endDate, onUpdate }) {
    const [dateMode, setDateMode] = useState('currentMonth')

    const handleDateModeChange = (dateMode) => {

        let queryStartDate = format(parseISO(startDate), 'yyyy-MM-dd')
        let queryEndDate = format(parseISO(endDate), 'yyyy-MM-dd');

        if (dateMode === 'currentYear') {
            queryStartDate = format(startOfYear(new Date()), 'yyyy-MM-dd')
            queryEndDate = format(endOfYear(new Date()), 'yyyy-MM-dd')
        }
        if (dateMode === 'currentQuarter') {
            queryStartDate = format(startOfQuarter(new Date()), 'yyyy-MM-dd')
            queryEndDate = format(endOfQuarter(new Date()), 'yyyy-MM-dd')
        }
        if (dateMode === 'currentMonth') {
            queryStartDate = format(startOfMonth(new Date()), 'yyyy-MM-dd')
            queryEndDate = format(endOfMonth(new Date()), 'yyyy-MM-dd')
        }
        if (dateMode === 'pastMonth') {
            queryStartDate = format(startOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd')
            queryEndDate = format(endOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd')
        }
        if (dateMode === 'pastQuarter') {
            queryStartDate = format(startOfQuarter(subMonths(new Date(), 3)), 'yyyy-MM-dd')
            queryEndDate = format(endOfQuarter(subMonths(new Date(), 3)), 'yyyy-MM-dd')
        }
        if (dateMode === 'pastYear') {
            queryStartDate = format(startOfYear(subMonths(new Date(), 12)), 'yyyy-MM-dd')
            queryEndDate = format(endOfYear(subMonths(new Date(), 12)), 'yyyy-MM-dd')
        }

        setDateMode(dateMode);
        onUpdate({
            startDate: queryStartDate,
            endDate: queryEndDate
        })
    }

    const options = [
        {
            value: 'currentMonth',
            label: 'Current Month'
        },
        {
            value: 'currentQuarter',
            label: 'Current Quarter'
        },
        {
            value: 'currentYear',
            label: 'Current Year'
        },
        {
            value: 'pastMonth',
            label: 'Past Month'
        },
        {
            value: 'pastQuarter',
            label: 'Past Quarter'
        },
        {
            value: 'pastYear',
            label: 'Past Year'
        },
        {
            value: 'custom',
            label: 'Custom'
        }
    ]

    return <>
        <DesktopDatePicker
            label="Start Date"
            inputFormat="MM/dd/yyyy"
            value={new Date(`${startDate}T00:00:00`)}
            onChange={d => {
                setDateMode('custom')
                onUpdate({
                    startDate: format(d, 'yyyy-MM-dd'),
                    endDate
                })
            }}
            renderInput={(params) => <TextField {...params} />}
        />
        <DesktopDatePicker
            label="End Date"
            inputFormat="MM/dd/yyyy"
            value={new Date(`${endDate}T00:00:00`)}
            onChange={d => {
                setDateMode('custom')
                onUpdate({
                    startDate,
                    endDate: format(d, 'yyyy-MM-dd')
                })
            }}
            renderInput={(params) => <TextField {...params} />}
        />
        <TextField
            label="Presets"
            select
            value={dateMode}
            onChange={(e) => { handleDateModeChange(e.target.value) }}>
            {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    </>
}