import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { format, startOfMonth, endOfMonth } from 'date-fns'
// component

// ----------------------------------------------------------------------


// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Container,
  Typography,
  TableContainer
} from '@mui/material';
import NumberFormat from 'react-number-format';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSearchParams, useNavigate, createSearchParams, NavLink } from 'react-router-dom';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import DateSelector from '../components/DateSelector';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/saleItems';
import Iconify from '../components/Iconify';
import { useApi } from '../api'
import OptionsButton from '../components/OptionsButton';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'clientName', label: 'Account', alignRight: false },
  { id: 'salesCount', label: 'Sales Count', alignRight: true },
  { id: 'avgBasketTotal', label: 'Avg Sale $', alignRight: true },
  { id: 'salesItemCount', label: 'Product Count', alignRight: true },
  { id: 'avgBasketCount', label: 'Avg Sale Qty', alignRight: true },
  { id: 'calcFIFOCost', label: 'Cost Fifo', alignRight: true },
  { id: 'calcAvgCost', label: 'Cost Avg', alignRight: true },
  { id: 'calcSubtotal', label: 'SubTotal', alignRight: true },
  { id: 'calcDiscount', label: 'Discount', alignRight: true },
  { id: 'calcTax', label: 'Tax', alignRight: true },
  { id: 'calcTotal', label: 'Total', alignRight: true },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, ({ clientName }) => {
      const value = clientName || ''
      return value.toLowerCase().indexOf(query.toLowerCase()) !== -1
    });
  }
  return stabilizedThis.map((el) => el[0]);
}


export default function User() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { get } = useApi();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [sales, setSales] = useState([]);
  const [open, setOpen] = useState()

  const startDate = searchParams.get('startDate') || format(startOfMonth(new Date()), 'yyyy-MM-dd')
  const endDate = searchParams.get('endDate') || format(endOfMonth(new Date()), 'yyyy-MM-dd')
  const categoryName = searchParams.get('categoryName') || ''
  const manufacturerName = searchParams.get('manufacturerName') || ''
  const vendorName = searchParams.get('vendorName') || ''
  const itemName = searchParams.get('itemName') || ''
  const upc = searchParams.get('upc') || ''

  const setParams = (updates = {}) => {
    const params = {
      startDate: updates.startDate,
      endDate: updates.endDate
    }

    navigate({
      pathname: "/dashboard/franchise-summary",
      search: createSearchParams(params).toString(),
      replace: true
    })
  }

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('clientName');

  const [filterName, setFilterName] = useState('');

  useEffect(() => {
    setIsLoading(true)
    setSales([]);

    const fetchData = async () => {
      try {
        const results = await get(`franchiseSummary?startDate=${startDate}&endDate=${endDate}&categoryName=${encodeURIComponent(categoryName)}&manufacturerName=${encodeURIComponent(manufacturerName)}&vendorName=${encodeURIComponent(vendorName)}&itemName=${encodeURIComponent(itemName)}&upc=${encodeURIComponent(upc)}`)
        setSales(results.franchiseSummary);
        setIsLoading(false)
      }
      catch (e) {
        console.log('error', e)
      }
    }

    fetchData()
  }, [get, startDate, endDate, categoryName, manufacturerName, vendorName, itemName, upc])



  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };



  const filteredSales = applySortFilter(sales, getComparator(order, orderBy), filterName);

  const totalSalesCount = filteredSales.map(e => e.salesCount ? parseFloat(e.salesCount) : 0).reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  const totalSalesItemCount = filteredSales.map(e => e.salesItemCount ? parseFloat(e.salesItemCount) : 0).reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  const totalCalcFIFOCost = filteredSales.map(e => e.calcFIFOCost ? parseFloat(e.calcFIFOCost) : 0).reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  const totalCalcAvgCost = filteredSales.map(e => e.calcAvgCost ? parseFloat(e.calcAvgCost) : 0).reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  const totalCalcSubtotal = filteredSales.map(e => e.calcSubtotal ? parseFloat(e.calcSubtotal) : 0).reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  const totalCalcDiscount = filteredSales.map(e => e.calcDiscount ? parseFloat(e.calcDiscount) : 0).reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  const totalCalcTax = filteredSales.map(e => e.calcTax ? parseFloat(e.calcTax) : 0).reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  const totalCalcTotal = filteredSales.map(e => e.calcTotal ? parseFloat(e.calcTotal) : 0).reduce((previousValue, currentValue) => previousValue + currentValue, 0)

  const totalAvgSale = totalSalesCount > 0 ? totalCalcTotal / totalSalesCount : 0
  const totalAvgCount = totalSalesCount > 0 ? totalSalesItemCount / totalSalesCount : 0
  return (
    <Page title="User">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Franchise Summary
          </Typography>
        </Stack>

        <Dialog
          open={Boolean(open)}
          onClose={() => { setOpen(false) }}
          scroll={'body'}
          maxWidth='lg'
          fullWidth
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">Data</DialogTitle>
          <DialogContent>
            <DialogContentText
              id="scroll-dialog-description"
              tabIndex={-1}
            >
              {open ? Object.entries(open).map((e, index) => <div key={index} style={{ display: 'flex', padding: '5px', borderBottom: '1px solid grey' }}>
                <div style={{ flex: '1' }}>{e[0]}</div>
                <div style={{ flex: '1', textAlign: 'right' }}>{e[1]}</div>
              </div>) : null}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { setOpen(false) }}>Cancel</Button>
          </DialogActions>
        </Dialog>

        <Card>

          <div style={{ display: 'flex', margin: '20px 0' }}>
            <div style={{ flex: 1 }}>
              <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} />
            </div>
            <div style={{ flex: 1 }}>
              <DateSelector
                startDate={startDate}
                endDate={endDate}
                onUpdate={setParams}
              />
            </div>
          </div>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  onRequestSort={handleRequestSort}
                />

                <TableBody>
                  {filteredSales.map((row, index) => {
                    const { clientId, clientName = '', salesCount, avgBasketTotal, salesItemCount, avgBasketCount, calcSubtotal, calcDiscount, calcAvgCost, calcFIFOCost, calcTotal, calcTax } = row;

                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                      >
                        <TableCell align="left">
                          <NavLink to={`/dashboard/sales?clientId=${clientId}&startDate=${startDate}&endDate=${endDate}&categoryName=${encodeURIComponent(categoryName)}&manufacturerName=${encodeURIComponent(manufacturerName)}&vendorName=${encodeURIComponent(vendorName)}&itemName=${encodeURIComponent(itemName)}&upc=${encodeURIComponent(upc)}`}>{clientName}</NavLink>
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            value={salesCount}
                            displayType={'text'}
                            thousandSeparator
                            decimalScale={0}
                            fixedDecimalScale
                          />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            value={avgBasketTotal}
                            displayType={'text'}
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            value={salesItemCount}
                            displayType={'text'}
                            thousandSeparator
                            decimalScale={0}
                            fixedDecimalScale
                          />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            value={avgBasketCount}
                            displayType={'text'}
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            value={calcFIFOCost}
                            displayType={'text'}
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            value={calcAvgCost}
                            displayType={'text'}
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            value={calcSubtotal}
                            displayType={'text'}
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            value={calcDiscount}
                            displayType={'text'}
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            value={calcTax}
                            displayType={'text'}
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            value={calcTotal}
                            displayType={'text'}
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        </TableCell>
                        <TableCell align="right">
                          <OptionsButton
                            options={[
                              {
                                name: 'View Sales',
                                onClick: () => {
                                  navigate(`/dashboard/sales?clientId=${clientId}&startDate=${startDate}&endDate=${endDate}&categoryName=${encodeURIComponent(categoryName)}&manufacturerName=${encodeURIComponent(manufacturerName)}&vendorName=${encodeURIComponent(vendorName)}&itemName=${encodeURIComponent(itemName)}&upc=${encodeURIComponent(upc)}`)
                                }
                              },
                              {
                                name: 'Source Data',
                                onClick: () => {
                                  setOpen(row)
                                }
                              }
                            ]}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {sales.length === 0 && (
                    <TableRow style={{ height: 53 }}>
                      <TableCell colSpan={TABLE_HEAD.length} style={{ textAlign: 'center' }}>
                        {isLoading ? 'Loading...' : 'No results found'}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>

                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell align="right"><NumberFormat value={totalSalesCount} displayType={'text'} thousandSeparator decimalScale={0} fixedDecimalScale /></TableCell>
                    <TableCell align="right"><NumberFormat value={totalAvgSale} displayType={'text'} thousandSeparator decimalScale={2} fixedDecimalScale /></TableCell>
                    <TableCell align="right"><NumberFormat value={totalSalesItemCount} displayType={'text'} thousandSeparator decimalScale={0} fixedDecimalScale /></TableCell>
                    <TableCell align="right"><NumberFormat value={totalAvgCount} displayType={'text'} thousandSeparator decimalScale={2} fixedDecimalScale /></TableCell>
                    <TableCell align="right"><NumberFormat value={totalCalcFIFOCost} displayType={'text'} thousandSeparator decimalScale={2} fixedDecimalScale /></TableCell>
                    <TableCell align="right"><NumberFormat value={totalCalcAvgCost} displayType={'text'} thousandSeparator decimalScale={2} fixedDecimalScale /></TableCell>
                    <TableCell align="right"><NumberFormat value={totalCalcSubtotal} displayType={'text'} thousandSeparator decimalScale={2} fixedDecimalScale /></TableCell>
                    <TableCell align="right"><NumberFormat value={totalCalcDiscount} displayType={'text'} thousandSeparator decimalScale={2} fixedDecimalScale /></TableCell>
                    <TableCell align="right"><NumberFormat value={totalCalcTax} displayType={'text'} thousandSeparator decimalScale={2} fixedDecimalScale /></TableCell>
                    <TableCell align="right"><NumberFormat value={totalCalcTotal} displayType={'text'} thousandSeparator decimalScale={2} fixedDecimalScale /></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}
