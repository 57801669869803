import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { format, startOfMonth, endOfMonth } from 'date-fns'
// component

// ----------------------------------------------------------------------


// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Container,
  Typography,
  TableContainer
} from '@mui/material';
import NumberFormat from 'react-number-format';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSearchParams, useNavigate, createSearchParams } from 'react-router-dom';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import DateSelector from '../components/DateSelector';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/saleItems';
import Iconify from '../components/Iconify';
import { useApi } from '../api'
import OptionsButton from '../components/OptionsButton';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'shopName', label: 'Shop', alignRight: false },
  { id: 'retailSubtotal', label: 'Retail SubTotal', alignRight: true },
  { id: 'retailDiscount', label: 'Retail Discount', alignRight: true },
  { id: 'retailAfterDiscount', label: 'After Discount', alignRight: true },
  { id: 'retailTax', label: 'Retail Tax', alignRight: true },
  { id: 'retailTotal', label: 'Retail Total', alignRight: true },
  { id: 'serviceSubtotal', label: 'Service SubTotal', alignRight: true },
  { id: 'serviceDiscount', label: 'Service Discount', alignRight: true },
  { id: 'serviceAfterDiscount', label: 'After Discount', alignRight: true },
  { id: 'serviceTax', label: 'Service Tax', alignRight: true },
  { id: 'serviceTotal', label: 'Service Total', alignRight: true },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.clientName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}


export default function User() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { get } = useApi();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [sales, setSales] = useState([]);
  const [open, setOpen] = useState()

  const startDate = searchParams.get('startDate') || format(startOfMonth(new Date()), 'yyyy-MM-dd')
  const endDate = searchParams.get('endDate') || format(endOfMonth(new Date()), 'yyyy-MM-dd')

  const setParams = (updates = {}) => {
    const params = {
      startDate: updates.startDate,
      endDate: updates.endDate
    }

    navigate({
      pathname: "/dashboard/royalty-report-new",
      search: createSearchParams(params).toString(),
      replace: true
    })
  }

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('clientName');

  const [filterName, setFilterName] = useState('');

  useEffect(() => {
    setIsLoading(true)
    setSales([]);

    const fetchData = async () => {
      try {
        const results = await get(`royaltyReport2?startDate=${startDate}&endDate=${endDate}`)

        setSales(results.royaltyReport);
        setIsLoading(false)
      }
      catch (e) {
        console.log('error', e)
      }
    }

    fetchData()
  }, [get, startDate, endDate])



  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };



  const filteredSales = applySortFilter(sales, getComparator(order, orderBy), filterName);

  const totalRetailSubtotal = filteredSales.map(e => e.retailSubtotal ? parseFloat(e.retailSubtotal) : 0).reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  const totalRetailDiscount = filteredSales.map(e => e.retailDiscount ? parseFloat(e.retailDiscount) : 0).reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  const totalRetailAfterDiscount = totalRetailSubtotal - totalRetailDiscount
  const totalRetailTax = filteredSales.map(e => e.retailTax ? parseFloat(e.retailTax) : 0).reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  const totalRetailTotal = filteredSales.map(e => e.retailTotal ? parseFloat(e.retailTotal) : 0).reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  const totalServiceSubtotal = filteredSales.map(e => e.serviceSubtotal ? parseFloat(e.serviceSubtotal) : 0).reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  const totalServiceDiscount = filteredSales.map(e => e.serviceDiscount ? parseFloat(e.serviceDiscount) : 0).reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  const totalServiceAfterDiscount = totalServiceSubtotal - totalServiceDiscount
  const totalServiceTax = filteredSales.map(e => e.serviceTax ? parseFloat(e.serviceTax) : 0).reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  const totalServiceTotal = filteredSales.map(e => e.serviceTotal ? parseFloat(e.serviceTotal) : 0).reduce((previousValue, currentValue) => previousValue + currentValue, 0)

  return (
    <Page title="User">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Royalty Report
          </Typography>
        </Stack>

        <Dialog
          open={Boolean(open)}
          onClose={() => { setOpen(false) }}
          scroll={'body'}
          maxWidth='lg'
          fullWidth
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">Data</DialogTitle>
          <DialogContent>
            <DialogContentText
              id="scroll-dialog-description"
              tabIndex={-1}
            >
              {open ? Object.entries(open).map((e, index) => <div key={index} style={{ display: 'flex', padding: '5px', borderBottom: '1px solid grey' }}>
                <div style={{ flex: '1' }}>{e[0]}</div>
                <div style={{ flex: '1', textAlign: 'right' }}>{e[1]}</div>
              </div>) : null}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { setOpen(false) }}>Cancel</Button>
          </DialogActions>
        </Dialog>

        <Card>

          <div style={{ display: 'flex', margin: '20px 0' }}>
            <div style={{ flex: 1 }}>
              <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} />
            </div>
            <div style={{ flex: 1 }}>
              <DateSelector
                startDate={startDate}
                endDate={endDate}
                onUpdate={setParams}
              />
            </div>
          </div>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  onRequestSort={handleRequestSort}
                />

                <TableBody>
                  {filteredSales.map((row) => {
                    const { clientId, shopID, shopName, retailSubtotal, retailDiscount, retailAfterDiscount, retailTax, retailTotal, serviceSubtotal, serviceDiscount, serviceAfterDiscount, serviceTax, serviceTotal } = row;

                    return (
                      <TableRow
                        hover
                        key={`${clientId}-${shopID}`}
                        tabIndex={-1}
                      >
                        <TableCell align="left">
                          {shopName}
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            value={retailSubtotal}
                            displayType={'text'}
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            value={retailDiscount}
                            displayType={'text'}
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            value={retailAfterDiscount}
                            displayType={'text'}
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            value={retailTax}
                            displayType={'text'}
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            value={retailTotal}
                            displayType={'text'}
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            value={serviceSubtotal}
                            displayType={'text'}
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            value={serviceDiscount}
                            displayType={'text'}
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            value={serviceAfterDiscount}
                            displayType={'text'}
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            value={serviceTax}
                            displayType={'text'}
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            value={serviceTotal}
                            displayType={'text'}
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        </TableCell>
                        <TableCell align="right">
                          <OptionsButton
                            options={[
                              {
                                name: 'Source Data',
                                onClick: () => {
                                  setOpen(row)
                                }
                              }
                            ]}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {sales.length === 0 && (
                    <TableRow style={{ height: 53 }}>
                      <TableCell colSpan={TABLE_HEAD.length} style={{ textAlign: 'center' }}>
                        {isLoading ? 'Loading...' : 'No results found'}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>

                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell align="right"><NumberFormat value={totalRetailSubtotal} displayType={'text'} thousandSeparator decimalScale={2} fixedDecimalScale /></TableCell>
                    <TableCell align="right"><NumberFormat value={totalRetailDiscount} displayType={'text'} thousandSeparator decimalScale={2} fixedDecimalScale /></TableCell>
                    <TableCell align="right"><NumberFormat value={totalRetailAfterDiscount} displayType={'text'} thousandSeparator decimalScale={2} fixedDecimalScale /></TableCell>
                    <TableCell align="right"><NumberFormat value={totalRetailTax} displayType={'text'} thousandSeparator decimalScale={2} fixedDecimalScale /></TableCell>
                    <TableCell align="right"><NumberFormat value={totalRetailTotal} displayType={'text'} thousandSeparator decimalScale={2} fixedDecimalScale /></TableCell>
                    <TableCell align="right"><NumberFormat value={totalServiceSubtotal} displayType={'text'} thousandSeparator decimalScale={2} fixedDecimalScale /></TableCell>
                    <TableCell align="right"><NumberFormat value={totalServiceDiscount} displayType={'text'} thousandSeparator decimalScale={2} fixedDecimalScale /></TableCell>
                    <TableCell align="right"><NumberFormat value={totalServiceAfterDiscount} displayType={'text'} thousandSeparator decimalScale={2} fixedDecimalScale /></TableCell>
                    <TableCell align="right"><NumberFormat value={totalServiceTax} displayType={'text'} thousandSeparator decimalScale={2} fixedDecimalScale /></TableCell>
                    <TableCell align="right"><NumberFormat value={totalServiceTotal} displayType={'text'} thousandSeparator decimalScale={2} fixedDecimalScale /></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}
