import { faker } from '@faker-js/faker';
import { useState, useEffect } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Stack, Box, Grid, Container, CardContent, Typography, Card, CardHeader, TextField, CircularProgress, MenuItem, Button } from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import { useApi } from '../api'

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const [sales, setSales] = useState([])
  const [upcList, setUpcList] = useState([])
  const [upc, setUpc] = useState('')
  const { get } = useApi();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true)
  const [items, setItems] = useState([])
  const [shops, setShops] = useState([])
  const [selectedShop, setSelectedShop] = useState('')
  const [selectedShop2, setSelectedShop2] = useState('')
  const [opportunities, setOpportunities] = useState([])
  const [isLoading2, setIsLoading2] = useState(false)
  const [showExtraNames, setShowExtraNames] = useState(true)

  useEffect(() => {
    setSales([]);
    setIsLoading(true)

    const fetchData = async () => {
      try {
        const shopResults = await get(`shops`)
        setShops(shopResults.shops);

        if (upcList.length > 0) {
          const itemsResults = await get(`items?upc=${upcList.join(',')}`)
          setItems(itemsResults.items);

          const results = await get(`salesSummary?groupBy=month&upc=${upcList.join(',')}`)
          setSales(results.salesSummary);
        }

      }
      catch (e) {
        console.log('error', e)
      }


      setIsLoading(false)
    }

    fetchData()
  }, [get, upcList])

  useEffect(() => {
    setOpportunities([]);
    setIsLoading2(true)

    const fetchData = async () => {
      try {
        if (selectedShop2 !== '') {
          const store = selectedShop2.split("-")
          const today = new Date();
          const priorDate = new Date(new Date().setDate(today.getDate() - 30)).toISOString().split('T')[0];

          const results = await get(`opportunities?dateFrom=${priorDate}&clientId=${store[0]}&shopId=${store[1]}&limit=20`)
          setOpportunities(results.opportunities);
        }

      }
      catch (e) {
        console.log('error', e)
      }

      setIsLoading2(false)
    }

    fetchData()
  }, [get, selectedShop2])

  console.log('shops', shops)

  const data2024 = []
  for (let i = 1; i <= 12; i += 1) {
    let total = 0;
    sales.forEach(e => {
      if (e.year === 2024 && e.month === i) {
        if (selectedShop === '') {
          total += parseInt(e.calcTotal, 10)
        }
        console.log(`${e.clientId}-${e.shopId}`)
        if (selectedShop === `${e.clientId}-${e.shopId}`) {
          total += parseInt(e.calcTotal, 10)
        }
      }
    })

    data2024.push(total)
  }


  const data2023 = []
  for (let i = 1; i <= 12; i += 1) {
    let total = 0;
    sales.forEach(e => {
      if (e.year === 2023 && e.month === i) {
        if (selectedShop === '') {
          total += parseInt(e.calcTotal, 10)
        }
        if (selectedShop === `${e.clientId}-${e.shopId}`) {
          total += parseInt(e.calcTotal, 10)
        }
      }
    })

    data2023.push(total)
  }


  const data2022 = []
  for (let i = 1; i <= 12; i += 1) {
    let total = 0;
    sales.forEach(e => {
      if (e.year === 2022 && e.month === i) {
        if (selectedShop === '') {
          total += parseInt(e.calcTotal, 10)
        }
        if (selectedShop === `${e.clientId}-${e.shopId}`) {
          total += parseInt(e.calcTotal, 10)
        }
      }
    })

    data2022.push(total)
  }

  const handleAddUpc = (e) => {
    setUpcList(currentState => {
      return [...upc.split('\n'), ...currentState]
    })

    setUpc('')

    return false;
  }

  const handleRemoveUpc = index => {
    setUpcList(currentState => {
      currentState.splice(index, 1)
      return [...currentState]
    })
  }

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Weekly Sales" total={714000} icon={'ant-design:android-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="New Users" total={1352831} color="info" icon={'ant-design:apple-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Item Orders" total={1723315} color="warning" icon={'ant-design:windows-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Bug Reports" total={234} color="error" icon={'ant-design:bug-filled'} />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            {isLoading
              ? <Card>
                <CardHeader title='Sales' />
                <CardContent>
                  <CircularProgress />
                </CardContent>
              </Card>
              : <AppWebsiteVisits
                title="Sales"
                chartLabels={[
                  'January',
                  'February',
                  'March',
                  'April',
                  'May',
                  'June',
                  'July',
                  'August',
                  'September',
                  'October',
                  'November',
                  'December',
                ]}
                chartData={[
                  {
                    name: '2024',
                    type: 'line',
                    fill: 'solid',
                    data: data2024,
                  },
                  {
                    name: '2023',
                    type: 'line',
                    fill: 'solid',
                    data: data2023,
                  },
                  {
                    name: '2022',
                    type: 'line',
                    fill: 'solid',
                    data: data2022,
                  },
                ]}
              />
            }
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Card>
              <CardHeader title='Filters' />
              <CardContent>
                <TextField
                  fullWidth
                  select
                  type="text"
                  label="Store"
                  onChange={e => { setSelectedShop(e.target.value) }}
                  value={selectedShop}
                >
                  <MenuItem key='all' value=''>
                    Show All
                  </MenuItem>
                  {shops.map((shop, index) => {
                    return <MenuItem key={index} value={`${shop.clientId}-${shop.shopID}`}>
                      {shop.name}
                    </MenuItem>
                  })}
                </TextField>

                <TextField
                  fullWidth
                  multiline
                  type="text"
                  label="UPC"
                  onChange={e => { setUpc(e.target.value) }}
                  value={upc}
                />
                <Button onClick={handleAddUpc} >Add</Button>
                <Button onClick={() => { setShowExtraNames(!showExtraNames) }} >{showExtraNames ? 'Hide Extra Names' : 'Show Extra Names'}</Button>

                <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
                  {upcList.map((upc, index) => {
                    const names = items.filter(e => e.upc === upc).map(e => e.description)
                    const uniqueNames = [...new Set(names)].sort()

                    const nameList = showExtraNames ? uniqueNames.map(e => <div>{e}</div>) : uniqueNames.slice(0, 1).map(e => <div>{e}</div>)

                    return <Stack direction="row" alignItems="center" spacing={2}>
                      <Box sx={{ minWidth: 240, flexGrow: 1 }}>
                        <Typography color="inherit" variant="subtitle2" >
                          {upc}
                        </Typography>

                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          {nameList}
                        </Typography>
                      </Box>
                      <Box onClick={() => { handleRemoveUpc(index) }}>
                        X
                      </Box>
                    </Stack>
                  })}

                </Stack>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <TextField
              fullWidth
              select
              type="text"
              label="Store"
              onChange={e => { setSelectedShop2(e.target.value) }}
              value={selectedShop2}
            >
              <MenuItem key='all' value='' />
              {shops.map((shop, index) => {
                return <MenuItem key={index} value={`${shop.clientId}-${shop.shopID}`}>
                  {shop.name}
                </MenuItem>
              })}
            </TextField>
            {isLoading2
              ? <Card>
                <CardHeader title='Sales' />
                <CardContent>
                  <CircularProgress />
                </CardContent>
              </Card>
              : <AppConversionRates
                title="Product Opportunities"
                subheader="Top seller you don't sell"
                chartData={opportunities.map(oppo => {
                  const names = oppo.names.split(";;")

                  return { label: names[0], subtitle: 'test', value: oppo.salesTotal }
                })
                }
              />
            }

          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Current Visits"
              chartData={[
                { label: 'America', value: 4344 },
                { label: 'Asia', value: 5435 },
                { label: 'Europe', value: 1443 },
                { label: 'Africa', value: 4443 },
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.chart.blue[0],
                theme.palette.chart.violet[0],
                theme.palette.chart.yellow[0],
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/static/mock-images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} height={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} height={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} height={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} height={32} />,
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
