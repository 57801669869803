import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import NumberFormat from 'react-number-format';
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/saleItems';
import { useApi } from '../api';

// ----------------------------------------------------------------------

const TABLE_HEAD_ALT = [
  { id: 'account', label: 'Account', alignRight: false },
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'date', label: 'Date', alignRight: false },
  { id: 'subtotal', label: 'SubTotal', alignRight: true },
  { id: 'discount', label: 'Discount', alignRight: true },
  { id: 'tax', label: 'Tax', alignRight: true },
  { id: 'total', label: 'Total', alignRight: true },
];

const TABLE_HEAD = [
  { id: 'itemName', label: 'Item', alignRight: false },
  { id: 'vendorName', label: 'Vendor', alignRight: false },
  { id: 'categoryName', label: 'Category', alignRight: false },
  { id: 'manufacturerName', label: 'Manufacturer', alignRight: false },
  { id: 'avgCost', label: 'Avg Cost', alignRight: true },
  { id: 'fifoCost', label: 'fifo Cost', alignRight: true },
  { id: 'margin', label: 'Margin', alignRight: true },
  { id: 'unitQuantity', label: 'Quantity', alignRight: true },
  { id: 'unitPrice', label: 'Price', alignRight: true },
  { id: 'calcSubtotal', label: 'Subtotal', alignRight: true },
  { id: 'calcLineDiscount', label: 'Discount', alignRight: true },
  { id: 'calcTax', label: 'Tax', alignRight: true },
  { id: 'calcTotal', label: 'Total', alignRight: true }
];


// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function User() {
  const { get } = useApi();
  const [sale, setSale] = useState({})
  const [saleLines, setSaleLines] = useState([])
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('itemName');
  const [searchParams, setSearchParams] = useSearchParams();

  const [filterName, setFilterName] = useState('currentQuarter');

  const [rowsPerPage, setRowsPerPage] = useState(5);


  useEffect(() => {
    const accountId = searchParams.get('accountId')
    const saleId = searchParams.get('saleId')

    const params = [];
    if (accountId) {
      params.push(`accountId=${accountId}`)
    }
    if (saleId) {
      params.push(`saleId=${saleId}`)
    }


    const fetchData = async () => {
      try {
        const salesData = await get(`sales/${saleId}?${params.join('&')}`)
        setSale(salesData.sale);

        const results = await get(`saleLines?${params.join('&')}`)
        setSaleLines(results.saleLines);
      }
      catch (e) {
        console.log('error', e)
      }
    }

    fetchData()
  }, [])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredSaleLines = applySortFilter(saleLines, getComparator(order, orderBy));

  const exclusions = () => {
    if (saleLines.filter(e => e.excluded === 'true').length === 0) {
      return null
    }

    return <div>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h4" gutterBottom>
          Exclusions
        </Typography>
      </Stack>
      <Card style={{ marginBottom: '50px' }}>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD_ALT}
                rowCount={USERLIST.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                <TableRow
                  hover
                  tabIndex={-1}
                >
                  <TableCell align="left">Nelas</TableCell>
                  <TableCell align="left" />
                  <TableCell align="left" />
                  <TableCell align="left" />
                  <TableCell align="right">{sale.excludedSubtotal}</TableCell>
                  <TableCell align="right">{sale.excludedDiscount}</TableCell>
                  <TableCell align="right">{sale.excludedTax}</TableCell>
                  <TableCell align="right">{sale.excludedTotal}</TableCell>
                </TableRow>
                <TableRow
                  hover
                  tabIndex={-1}
                >
                  <TableCell align="left">Adjusted Total</TableCell>
                  <TableCell align="left" />
                  <TableCell align="left" />
                  <TableCell align="left" />
                  <TableCell align="right">{sale.adjustedSubtotal}</TableCell>
                  <TableCell align="right">{sale.adjustedDiscount}</TableCell>
                  <TableCell align="right">{sale.adjustedTax}</TableCell>
                  <TableCell align="right">{sale.adjustedTotal}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
    </div>
  }


  return (
    <Page title="User">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            Sale
          </Typography>
        </Stack>
        <Card style={{ marginBottom: '50px' }}>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD_ALT}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  <TableRow
                    hover
                    tabIndex={-1}
                  >
                    <TableCell align="left">{sale.clientName}</TableCell>
                    <TableCell align="left">{sale.saleID}</TableCell>
                    <TableCell align="left">{sale.adjustedTimeStamp}</TableCell>
                    <TableCell align="right">{sale.calcSubtotal}</TableCell>
                    <TableCell align="right">{sale.calcDiscount}</TableCell>
                    <TableCell align="right">{sale.taxTotal}</TableCell>
                    <TableCell align="right">{sale.calcTotal}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>


        {exclusions()}


        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            Line Items
          </Typography>
        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredSaleLines.map((row) => {
                    const { excluded, saleLineId, itemName, margin, vendorName, categoryName, manufacturerName, calcSubtotal, unitPrice, calcLineDiscount, calcTax, calcTotal, avgCost, fifoCost, unitQuantity, tax } = row;

                    return (
                      <TableRow
                        hover
                        key={saleLineId}
                        tabIndex={-1}
                        style={{ backgroundColor: excluded === 'true' ? 'salmon' : 'white' }}
                      >
                        <TableCell align="left">{itemName}</TableCell>
                        <TableCell align="left">{vendorName}</TableCell>
                        <TableCell align="left">{categoryName}</TableCell>
                        <TableCell align="left">{manufacturerName}</TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            value={avgCost}
                            displayType={'text'}
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            value={fifoCost}
                            displayType={'text'}
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            value={margin}
                            displayType={'text'}
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            value={unitQuantity}
                            displayType={'text'}
                            thousandSeparator
                            decimalScale={0}
                            fixedDecimalScale
                          />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            value={unitPrice}
                            displayType={'text'}
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            value={calcSubtotal}
                            displayType={'text'}
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            value={calcLineDiscount}
                            displayType={'text'}
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            value={calcTax}
                            displayType={'text'}
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            value={calcTotal}
                            displayType={'text'}
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={7} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}
