// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Royalty Report',
    path: '/dashboard/royalty-report-new',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'Franchise Summary',
    path: '/dashboard/franchise-summary',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'Category Summary',
    path: '/dashboard/category-summary',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'Brand Summary',
    path: '/dashboard/manufacturer-summary',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'UPC Summary',
    path: '/dashboard/upc-summary',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'Item Summary',
    path: '/dashboard/item-summary',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'Vendor Summary',
    path: '/dashboard/vendor-summary',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'sales',
    path: '/dashboard/sales',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'sale-details',
    path: '/dashboard/sale-details',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'Lightspeed Accounts',
    path: '/dashboard/accounts',
    icon: getIcon('eva:people-fill'),
  },
];

export default navConfig;
